import { Button, styled, Toolbar } from '@mui/material';

import { DistribId, Kpis } from 'utils/types';
import './KpiBar.css';

interface IKpiBarProps {
  kpis: Kpis;
  distribId: DistribId;
  toggleMaxDuration: () => void;
  toggleMaxInvestmentAmount: () => void;
  toggleMaxPortfolioAmount: () => void;
  withRebalancing: boolean;
}

function KpiBar({
  kpis,
  distribId,
  toggleMaxDuration,
  toggleMaxInvestmentAmount,
  toggleMaxPortfolioAmount,
  withRebalancing,
}: IKpiBarProps) {
  const getRiskKpis = (
    initialTitle: string,
    initialKey: keyof Kpis,
    finalTitle: string,
    finalKey: keyof Kpis,
  ) => (
    <div className="Column">
      <Kpi disabled disableRipple>
        <span>{initialTitle}</span>
        <span className="Kpi-value">{kpis[initialKey].formatAsAmount()}</span>
      </Kpi>
      <Kpi disabled disableRipple>
        <span>{finalTitle}</span>
        <span className="Kpi-value">{kpis[finalKey].formatAsAmount()}</span>
      </Kpi>
    </div>
  );

  return (
    <Toolbar className="Toolbar">
      <div className="Kpis-row">
        <div className="Column">
          <Kpi
            checked={kpis.maxDuration.checked}
            disabled={!kpis.maxDuration.valid()}
            disableRipple
            onClick={toggleMaxDuration}
          >
            <span>Max Duration</span>
            <span className="Kpi-value">{kpis.maxDuration.formatAsDuration()}</span>
          </Kpi>
          <Kpi disabled disableRipple>
            <span>Avg Duration</span>
            <span className="Kpi-value">{kpis.avgDuration.formatAsDuration()}</span>
          </Kpi>
        </div>
        <div className="Column">
          <Kpi disabled disableRipple>
            <span>% of requests under 2s</span>
            <span className="Kpi-value">{kpis.durationUnder2s.formatAsPercentage()}</span>
          </Kpi>
          <Kpi disabled disableRipple>
            <span>% of requests under 5s</span>
            <span className="Kpi-value">{kpis.durationUnder5s.formatAsPercentage()}</span>
          </Kpi>
        </div>
        {(distribId !== 'mfaaas' && distribId !== 'mfaaas_v2') ? (
          <>
            <div className="Column">
              <Kpi
                checked={kpis.maxPortfolioAmount.checked}
                disabled={!kpis.maxPortfolioAmount.valid()}
                disableRipple
                onClick={toggleMaxPortfolioAmount}
              >
                <span>Max Portfolio</span>
                <span className="Kpi-value">
                  {kpis.maxPortfolioAmount.formatAsAmount()}
                  &nbsp;€
                </span>
              </Kpi>
              <Kpi disabled disableRipple>
                <span>Avg Portfolio</span>
                <span className="Kpi-value">
                  {kpis.avgPortfolioAmount.formatAsAmount()}
                  &nbsp;€
                </span>
              </Kpi>
            </div>
            <div className="Column">
              <Kpi
                checked={kpis.maxInvestmentAmount.checked}
                disabled={!kpis.maxInvestmentAmount.valid()}
                disableRipple
                onClick={toggleMaxInvestmentAmount}
              >
                <span>Max Investment</span>
                <span className="Kpi-value">
                  {kpis.maxInvestmentAmount.formatAsAmount()}
                  &nbsp;€
                </span>
              </Kpi>
              <Kpi disabled disableRipple>
                <span>Avg Investment</span>
                <span className="Kpi-value">
                  {kpis.avgInvestmentAmount.formatAsAmount()}
                  &nbsp;€
                </span>
              </Kpi>
            </div>
          </>
        ) : (
          <>
            <div className="Column">
              <Kpi disabled disableRipple>
                <span>Avg Initial Tracking Error</span>
                <span className="Kpi-value">{kpis.avgInitialTrackingError.formatAsAmount()}</span>
              </Kpi>
              <Kpi disabled disableRipple>
                <span>Avg Final Tracking Error</span>
                <span className="Kpi-value">{kpis.avgFinalTrackingError.formatAsAmount()}</span>
              </Kpi>
            </div>
            <div className="Column">
              <Kpi disabled disableRipple>
                <span>Avg Initial ESG Weight</span>
                <span className="Kpi-value">{kpis.avgInitialESGWeight.formatAsAmount()}</span>
              </Kpi>
              <Kpi disabled disableRipple>
                <span>Avg Final ESG Weight</span>
                <span className="Kpi-value">{kpis.avgFinalESGWeight.formatAsAmount()}</span>
              </Kpi>
            </div>
          </>
        )}
        {['bpf', 'mfaaas', 'mfaaas_v2'].includes(distribId)
          ? getRiskKpis('Avg Initial Vol', 'avgInitialVol', 'Avg Final Vol', 'avgFinalVol')
          : getRiskKpis('Avg Initial RISK', 'avgInitialRisk', 'Avg Final RISK', 'avgFinalRisk')}
        <div className="Column">
          <Kpi disabled disableRipple>
            <span>Avg Initial Euro Fund weight</span>
            <span className="Kpi-value">{kpis.avgInitialEuroFundWeight.formatAsPercentage()}</span>
          </Kpi>
          <Kpi disabled disableRipple>
            <span>Avg Initial Reim UC weight</span>
            <span className="Kpi-value">{kpis.avgInitialReimUCWeight.formatAsPercentage()}</span>
          </Kpi>
          <Kpi disabled disableRipple>
            <span>Avg Initial UC weight</span>
            <span className="Kpi-value">{kpis.avgInitialUCWeight.formatAsPercentage()}</span>
          </Kpi>
        </div>
        <div className="Column">
          <Kpi disabled disableRipple>
            <span>Avg Final Euro Fund weight</span>
            <span className="Kpi-value">{kpis.avgFinalEuroFundWeight.formatAsPercentage()}</span>
          </Kpi>
          <Kpi disabled disableRipple>
            <span>Avg Final Reim UC weight</span>
            <span className="Kpi-value">{kpis.avgFinalReimUCWeight.formatAsPercentage()}</span>
          </Kpi>
          <Kpi disabled disableRipple>
            <span>Avg Final UC weight</span>
            <span className="Kpi-value">{kpis.avgFinalUCWeight.formatAsPercentage()}</span>
          </Kpi>
        </div>
      </div>
      {withRebalancing && (
        <div className="Column">
          <Kpi disabled disableRipple>
            <span>Max Funds in Portfolio during rebalancing</span>
            <span className="Kpi-value">{kpis.maxFundsDuringRebalancing.formatAsAmount()}</span>
          </Kpi>
          <Kpi disabled disableRipple>
            <span>Avg Funds in Portfolio during rebalancing</span>
            <span className="Kpi-value">{kpis.avgFundsDuringRebalancing.formatAsAmount()}</span>
          </Kpi>
        </div>
      )}
    </Toolbar>
  );
}

interface IKpiProps {
  checked?: boolean;
}

const Kpi = styled(Button)`
  background-color: ${(props: IKpiProps) => (props.checked ? '#00965E' : '#eee')};
  border-radius: 10px;
  justify-content: flex-start;
  margin-bottom: 12px;
  margin-right: 12px;
  padding: 3px 5px;
  color: ${(props) => (props.checked ? 'white' : '#000000de')};

  &:disabled {
    color: #000000de;
  }

  &:hover {
    color: ${(props) => (props.checked ? 'white' : '#000000de')};
    background-color: ${(props) => (props.checked ? '#00a76f' : '')};
  }

  & > span {
    display: flex;
    text-transform: none;
  }
`;

export default KpiBar;
