import { forwardRef, useEffect, useImperativeHandle } from 'react';
import * as d3 from 'd3';
import { scaleTime } from 'd3-scale';
import { differenceInDays, format } from 'date-fns';
import { IChart, IVolumeChartProps } from 'utils/chartsUtils';
import useChart from './useChart';

const VolumeChart = forwardRef<IChart, IVolumeChartProps>(
  ({ beginDate, cf, endDate, onFilter }: IVolumeChartProps, ref) => {
    const [chart, dimension, group, chartRef] = useChart(cf, cf?.dc.lineChart, 'day');

    useEffect(() => {
      chart
        ?.brushOn(false)
        .clipPadding(50)
        .dimension(dimension)
        .elasticY(true)
        .group(group)
        .height(130)
        .on('filtered', () => {
          if (onFilter) {
            onFilter();
          }
        })
        .margins({ top: 10, right: 30, bottom: 40, left: 40 })
        .renderArea(true)
        .renderDataPoints({ radius: 2, fillOpacity: 0.8, strokeOpacity: 0.8 })
        .renderHorizontalGridLines(true)
        .title((d) => `${format(new Date(d.key), 'dddd dd MMMM yyyy')} : ${d.value} request(s)`)
        .width(950)
        .x(scaleTime().domain([beginDate, endDate]));

      chart
        ?.xAxis()
        .ticks(Math.min(differenceInDays(endDate, beginDate) + 1, 10))
        .tickFormat((v) => format(v, 'd MMM'));
      chart?.yAxis().ticks(3);
      chart?.yAxis().tickFormat(d3.format('.3s'));

      chart?.render();
    }, [beginDate, chart, dimension, endDate, group, onFilter]);

    useImperativeHandle(
      ref,
      () => ({
        update() {
          chart?.redraw();
        },
      }),
      [chart],
    );

    return <div data-chart-name="VolumeChart" ref={chartRef} />;
  },
);

export default VolumeChart;
