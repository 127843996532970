import { useEffect, useState } from 'react';

import {
  Column,
  SortDirection,
  Table,
  type RowMouseEventHandlerParams,
  type SortDirectionType,
} from 'react-virtualized';
import 'react-virtualized/styles.css';

import { type DistribId, type Log, type RawLog } from 'utils/types';
import { getLog } from '../../utils/api';
import { logColumnsMapping } from '../../utils/constants';

import { formatAmount } from '../../utils/numbersUtils';
import DetailsDialog from '../DetailsDialog/DetailsDialog';

import './LogTable.css';

export function sort(logs: Log[], sortBy: keyof Log, sortDirection: string) {
  logs.sort((log1, log2) => {
    let result = 0;

    const value1 = log1[sortBy];
    const value2 = log2[sortBy];

    if (!value1) return 1;
    if (!value2) return -1;

    if (value1 < value2) {
      result = -1;
    } else if (value1 > value2) {
      result = 1;
    }

    if (sortDirection === SortDirection.DESC) {
      return -result;
    }

    return result;
  });
}

interface ILogTableProps {
  logs: Log[];
  startWorking: () => void;
  stopWorking: () => void;
  onLogRequestError: (message: string) => void;
  distribId: DistribId;
}

function LogTable({ ...props }: ILogTableProps) {
  const [sortBy, setSortBy] = useState<keyof Log>('start_date');
  const [sortDirection, setSortDirection] = useState<SortDirectionType>(SortDirection.DESC);
  const [logs, setLogs] = useState<Log[]>([]);
  const [log, setLog] = useState<RawLog | null>(null);
  const [openDetails, setOpenDetails] = useState(false);

  const onHeaderClick = (info: { sortBy: string; sortDirection: SortDirectionType }) => {
    let direction = info.sortDirection;
    if (info.sortBy === sortBy) {
      if (sortDirection === SortDirection.ASC) {
        direction = SortDirection.DESC;
      } else {
        direction = SortDirection.ASC;
      }
    }

    const newLogs = props.logs;

    sort(newLogs, info.sortBy as keyof Log, direction);

    setLogs(newLogs);
    setSortBy(info.sortBy as keyof Log);
    setSortDirection(direction);
  };

  const onRowClick = ({ rowData }: RowMouseEventHandlerParams) => {
    props.startWorking();

    getLog(rowData.id)
      .then((rawLog) => {
        setLog({ ...rawLog, http_route: rowData.http_route, id: rowData.id });
        setOpenDetails(true);
      })
      .catch(() => {
        props.onLogRequestError(`Failed to get log ${rowData.id} data.`);
      })
      .finally(props.stopWorking);
  };

  const onDetailsClose = () => {
    setOpenDetails(false);
  };

  const getRowClassName = ({ index }: { index: number }) => {
    if (index < 0) {
      return 'Table-header';
    }
    const rowLog = logs[index];

    if (rowLog.status === 'Client Error') {
      return index % 2 === 0
        ? 'Table-even-row Table-client-error'
        : 'Table-odd-row Table-client-error';
    }
    if (rowLog.status === 'Server Error') {
      return index % 2 === 0
        ? 'Table-even-row Table-server-error'
        : 'Table-odd-row Table-server-error';
    }

    return index % 2 === 0 ? 'Table-even-row' : 'Table-odd-row';
  };

  useEffect(() => {
    setLogs(props.logs);
  }, [props.logs]);

  return (
    <div>
      <Table
        gridClassName="Table-grid"
        headerHeight={50}
        height={315}
        onRowClick={onRowClick}
        rowClassName={getRowClassName}
        rowCount={logs.length}
        rowGetter={({ index }) => logs[index]}
        rowHeight={20}
        sort={onHeaderClick}
        sortBy={sortBy}
        sortDirection={sortDirection}
        width={1500}
      >
        {logColumnsMapping.startDate[props.distribId] && (
          <Column dataKey="start_date" label="Date" width={140} />
        )}
        {logColumnsMapping.httpRoute[props.distribId] && (
          <Column dataKey="http_route" label="HTTP Route" width={180} />
        )}
        {logColumnsMapping.httpStatus[props.distribId] && (
          <Column
            className="App-column-center"
            dataKey="http_status"
            label="HTTP Status"
            width={60}
          />
        )}
        {logColumnsMapping.duration[props.distribId] && (
          <Column
            cellDataGetter={({ rowData }) => rowData.readable_duration}
            className="App-column-right"
            dataKey="duration_in_ms"
            label="Duration"
            width={80}
          />
        )}
        {logColumnsMapping.portfolioAmount[props.distribId] && (
          <Column
            cellDataGetter={({ rowData }) => formatAmount(rowData.portfolio_amount)}
            className="App-column-right"
            dataKey="portfolio_amount"
            label="Portfolio Amount"
            width={100}
          />
        )}
        {logColumnsMapping.investmentAmount[props.distribId] && (
          <Column
            cellDataGetter={({ rowData }) => formatAmount(rowData.investment_amount)}
            className="App-column-right"
            dataKey="investment_amount"
            label="Investment Amount"
            width={100}
          />
        )}
        {logColumnsMapping.initialVolatility[props.distribId] && (
          <Column
            cellDataGetter={({ rowData }) => formatAmount(rowData.initial_volatility)}
            className="App-column-center"
            dataKey="initial_volatility"
            label="Initial Vol"
            width={60}
          />
        )}
        {logColumnsMapping.initialRISK[props.distribId] && (
          <Column
            cellDataGetter={({ rowData }) => formatAmount(rowData.risk_before_operation)}
            className="App-column-center"
            dataKey="risk_before_operation"
            label="Initial RISK"
            width={60}
          />
        )}
        {logColumnsMapping.finalVolatility[props.distribId] && (
          <Column
            cellDataGetter={({ rowData }) => formatAmount(rowData.final_volatility)}
            className="App-column-center"
            dataKey="final_volatility"
            label="Final Vol"
            width={60}
          />
        )}
        {logColumnsMapping.finalRISK[props.distribId] && (
          <Column
            cellDataGetter={({ rowData }) => formatAmount(rowData.risk_after_operation)}
            className="App-column-center"
            dataKey="risk_after_operation"
            label="Final RISK"
            width={60}
          />
        )}
        {logColumnsMapping.targetVolatility[props.distribId] && (
          <Column
            cellDataGetter={({ rowData }) =>
              formatAmount(
                (props.distribId === 'mfaaas' || props.distribId === 'mfaaas_v2') ? rowData.target_volatility : rowData.target_sd,
              )
            }
            className="App-column-center"
            dataKey="target_volatility"
            label="Target Vol"
            width={60}
          />
        )}
        {logColumnsMapping.targetRISK[props.distribId] && (
          <Column
            cellDataGetter={({ rowData }) => formatAmount(rowData.target_risk)}
            className="App-column-center"
            dataKey="target_risk"
            label="Target RISK"
            width={60}
          />
        )}
        {logColumnsMapping.maxVolatility[props.distribId] && (
          <Column
            cellDataGetter={({ rowData }) =>
              formatAmount((props.distribId === 'mfaaas' || props.distribId === 'mfaaas_v2') ? rowData.max_volatility : rowData.max_sd)
            }
            className="App-column-center"
            dataKey="max_volatility"
            label="Max Vol"
            width={60}
          />
        )}
        {logColumnsMapping.errorType[props.distribId] && (
          <Column dataKey="error_type" label="Error Type" width={275} />
        )}
      </Table>
      {openDetails && log && (
        <DetailsDialog
          handleClose={onDetailsClose}
          log={log}
          startWorking={props.startWorking}
          stopWorking={props.stopWorking}
          setErrorMessage={props.onLogRequestError}
        />
      )}
    </div>
  );
}

export default LogTable;
