import { KeyboardEventHandler, useState } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Select,
  TextField,
  styled,
} from '@mui/material';

import { signIn } from '../../utils/api';

import { DialogButton, DialogButtons, StyledDialogTitle } from '../ErrorDialog/ErrorDialog';
import './SignInDialog.css';

interface ISignInDialogProps {
  handleSignIn: (token: string) => void;
}

function SignInDialog({ ...props }: ISignInDialogProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [application, setApplication] = useState<string>('tools_admin');
  const [open, setOpen] = useState(true);

  const handleClickShowPassword = () => {
    setShowPassword((prevstate) => !prevstate);
  };

  const handleKeyPress: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      handleSignIn();
    }
  };

  const handleSignIn = () => {
    setError(false);
    setFetching(true);

    signIn(email, password, application)
      .then((data) => {
        if (!error) {
          setOpen(false);
          props.handleSignIn(data.token);
        }
      })
      .finally(() => {
        if (open) {
          setFetching(false);
        }
      });
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown
      maxWidth="xs"
      onClose={handleSignIn}
      open={open}
    >
      <StyledDialogTitle id="form-dialog-title">Sign in to Smart Logs</StyledDialogTitle>
      <DialogContent>
        <DialogInput
          autoFocus
          variant="standard"
          disabled={fetching}
          error={error}
          fullWidth
          id="email"
          label="Email Address"
          margin="dense"
          onChange={(event) => setEmail(event.target.value)}
          onKeyPress={handleKeyPress}
          type="email"
        />
        <DialogInput
          disabled={fetching}
          error={error}
          fullWidth
          id="password"
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  title={showPassword ? 'Click to Hide Password' : 'Click to Show Password'}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Password"
          margin="dense"
          onChange={(event) => setPassword(event.target.value)}
          onKeyPress={handleKeyPress}
          type={showPassword ? 'text' : 'password'}
        />
        <FormControl className="Dialog-select">
          <InputLabel htmlFor="application">Application</InputLabel>
          <Select
            inputProps={{
              id: 'application',
            }}
            native
            onChange={(event) => setApplication(event.target.value)}
            value={application}
            variant="standard"
          >
            <option value="tools_admin">Tools Admin</option>
            <option value="parvest">Parvest</option>
            <option value="retail">Retail</option>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogButtons>
        <DialogButton color="primary" disabled={fetching} disableRipple onClick={handleSignIn}>
          Sign in
        </DialogButton>
      </DialogButtons>
      {fetching && (
        <LinearProgress
          classes={{
            colorPrimary: 'Dialog-progress-primary',
            barColorPrimary: 'Dialog-progress-secondary',
          }}
          variant="query"
        />
      )}
    </Dialog>
  );
}

const DialogInput = styled(TextField)`
  & > input {
    padding: 5px;
  }
`;

export default SignInDialog;
