import PieChart from 'components/charts/PieChart';
import RowChart from 'components/charts/RowChart';
import { Dimension, Group, NaturallyOrderedValue } from 'crossfilter2';
import dc from 'dc';
import { Charts, Cf, Log } from './types';

export interface IPieChartProps {
  logKey: string;
  defaultDimensionName?: string;
  title: string;
  colors?: { domain: string[]; range: string[] };
  dimensionFilter?: (log: Log) => string;
  cf: Cf | null;
  width?: number;
  height?: number;
  onFilter?: () => void;
}

export interface IVolumeChartProps {
  cf: Cf;
  beginDate: Date;
  endDate: Date;
  onFilter: () => void;
}

export interface IRowChartProps {
  logKey: string;
  cf: Cf | null;
  onFilter?: () => void;
  defaultDimensionName?: string;
  dimensionFilter?: (log: Log) => string;
  height: number;
  title: string;
  withLabel?: boolean;
  withDataGrouping?: boolean;
}

export interface IChart {
  update: () => void;
}

export type IUpdateChart<T> = (
  chart: T | undefined,
  dimension: Dimension<Log, any>,
  group: Group<Log, NaturallyOrderedValue, unknown>,
  dc: dc.Base,
) => void;

export const getChart = ({
  ...props
}: Charts & { cf: Cf; onFilter: () => void; logKey: string; ref: (ref: IChart) => void }) => {
  switch (props.name) {
    case 'pieChart':
      return <PieChart {...props} key={props.logKey} />;
    case 'rowChart':
      return <RowChart {...props} key={props.logKey} />;
    default:
      return <div />;
  }
};
