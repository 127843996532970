import { Dimension, Group, NaturallyOrderedValue } from 'crossfilter2';
import { startOfDay } from 'date-fns';
import { BaseMixin } from 'dc';
import {
  useState,
  useRef,
  MouseEventHandler,
  useCallback,
  useEffect,
  MutableRefObject,
} from 'react';
import { Cf, Log } from 'utils/types';

export default function useChart<T extends BaseMixin<T>>(
  cf: Cf | null,
  getChart: ((currentRef: any) => T) | undefined,
  logKey?: string,
  defaultDimensionName?: string | undefined,
  dimensionFilter?: (log: Log) => string,
) {
  const [chart, setChart] = useState<T>();
  const [dimension, setDimension] = useState<Dimension<Log, Date>>();
  const [group, setGroup] = useState<Group<Log, NaturallyOrderedValue, unknown>>();
  const chartRef = useRef(null);

  const reset: MouseEventHandler = (event) => {
    chart?.filterAll();
    event.preventDefault();
  };

  const isDimensionArray = useCallback(() => {
    const logs = cf?.ndx.allFiltered();
    if (logs && logs.length > 0) {
      return Array.isArray(logs[0][logKey as keyof Log]);
    }
    return false;
  }, [cf?.ndx, logKey]);

  const defaultDimensionFilter = useCallback(
    (log: { [key: string]: any }) => {
      if (logKey && (log[logKey] || log[logKey] === false)) {
        switch (logKey) {
          case 'day':
            return startOfDay(log[logKey]);
          case 'preselection':
            return log[logKey];
          default:
            return log[logKey].toString();
        }
      }

      return defaultDimensionName || 'Inconnu';
    },
    [defaultDimensionName, logKey],
  );

  const update = useCallback(() => {
    if (!cf) return;

    if (chartRef.current && getChart) {
      const newChart = getChart(chartRef.current);
      setChart((prevState) => {
        prevState?.resetSvg();
        return newChart;
      });

      const newDimension = cf.ndx.dimension((log) => {
        if (dimensionFilter) {
          return dimensionFilter(log);
        }
        return defaultDimensionFilter(log);
      }, isDimensionArray());

      setDimension((prevState) => {
        prevState?.dispose();
        return newDimension;
      });

      const newGroup = newDimension.group();

      setGroup((prevState) => {
        prevState?.dispose();
        return newGroup;
      });
    }
  }, [cf, defaultDimensionFilter, dimensionFilter, getChart, isDimensionArray]);

  useEffect(() => update(), [update]);

  return [chart, dimension, group, chartRef, reset] as [
    T,
    Dimension<Log, Date> | undefined,
    Group<Log, NaturallyOrderedValue, unknown> | undefined,
    MutableRefObject<null>,
    () => void,
  ];
}
