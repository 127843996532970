import crossfilter from 'crossfilter2';
import * as dc from 'dc';
import Kpi from 'utils/Kpi';

export type Cf = {
  dc: typeof dc;
  all: crossfilter.GroupAll<Log, number>;
  durationDimension: crossfilter.Dimension<Log, number>;
  investmentAmountDimension: crossfilter.Dimension<Log, number>;
  ndx: crossfilter.Crossfilter<Log>;
  portfolioAmountDimension: crossfilter.Dimension<Log, any>;
  isFromSATeamDimension: crossfilter.Dimension<Log, any>;
  userDimension: crossfilter.Dimension<Log, any>;
  userGroup: crossfilter.Group<Log, crossfilter.NaturallyOrderedValue, unknown>;
  rebalancingGroup: crossfilter.Group<Log, crossfilter.NaturallyOrderedValue, unknown>;
};

export type Charts =
  | {
      name: 'pieChart';
      logKey: string;
      defaultDimensionName?: string;
      title: string;
      colors?: { domain: string[]; range: string[] };
      dimensionFilter?: (log: Log) => string;
      height?: number;
      width?: number;
    }
  | {
      name: 'rowChart';
      logKey: string;
      defaultDimensionName?: string;
      title: string;
      height: number;
      withLabel?: boolean;
      withDataGrouping?: boolean;
    }
  | { name: 'empty'; logKey: string };

export type RawLog = {
  id: string;
  log_id: string;
  input_request: string;
  output_response: string;
  http_route: string;
};

type BaseLog = {
  is_from_smart_allocation_team: boolean | null;
  user_id: number | null;
  duration_in_ms: number;
  initial_volatility: number | null;
  final_volatility: number | null;
  composition_overview: {
    euro_fund_weight: number | null;
    reim_uc_weight: number | null;
    uc_weight: number | null;
  };
  initial_portfolio_overview: {
    euro_fund_weight: number | null;
    reim_uc_weight: number | null;
    uc_weight: number | null;
  };
  http_route: string | null;
  http_status: number;
  id: string | null;
  distributor_name: string | null;
  error_type: string | null;
  mandate_type: string | null;
  start_date: string;
  status: string;
  date: Date;
  day: Date;
  readable_duration: string;
  funds_in_portfolio: number | null;
};

export type LegacyLog = BaseLog & {
  risk_after_operation: number | null;
  risk_before_operation: number | null;
  investment_amount: number | null;
  portfolio_amount: number | null;
  age_user: number | null;
  application_id: string | null;
  channel: string | null;
  commit_hash: string | null;
  contract_id: string | null;
  portfolio_is_real?: boolean | null;
  currency: string | null;
  http_referer: string | null;
  initial_regular_investment_amount: number | null;
  initial_regular_investment_periodicity: number | null;
  investment_periodicity: 'monthly' | 'quarterly' | 'biannual' | 'annual' | null;
  max_sd: number | null;
  is_portfolio_real?: boolean | null;
  portfolio_type: string | null;
  preselection: any[] | null;
  risk_after_1_year: number | null;
  risk_profile_name: number | null;
  target_sd: number | null;
  target_risk: number | null;
  turnover_amount: number | null;
  readable_duration: string | null;
};

export type LogMfaaas = BaseLog & {
  max_volatility: number | null;
  target_volatility: number | null;
  initial_tracking_error: number | null;
  final_tracking_error: number | null;
  initial_esg_weight: number | null;
  final_esg_weight: number | null;
  with_esg_interest: boolean | null;
  esg_not_respected: boolean | null;
  portfolio_cannot_be_optimized: boolean | null;
};

export const isLegacyLog = (log: Log): log is LegacyLog =>
  (log as LegacyLog).target_sd !== undefined;
export const isLogMfaaas = (log: Log): log is LogMfaaas =>
  (log as LogMfaaas).target_volatility !== undefined;

export type Log = LegacyLog | LogMfaaas;

export type User = {
  role: 'ADMIN' | 'USER';
  profile: {
    firstname: string;
    lastname: string;
  };
};

export interface IDashboardProps {
  beginDate: Date;
  endDate: Date;
  cf: Cf | null;
  filteredLogs: Log[];
  distribId: DistribId;
  exportData: (beginDate: Date, endDate: Date, distribId: DistribId) => void;
  fetchData: (beginDate: Date, endDate: Date, distribId: DistribId) => void;
  kpis: Kpis;
  startWorking: () => void;
  stopWorking: () => void;
  updateCharts: () => void;
  workers: number;
  setErrorMessage: (errorMessage: string) => void;
  rebalancingBeginTime: Date;
  rebalancingEndTime: Date;
  setRebalancingBeginTime: (value: React.SetStateAction<Date>) => void;
  setRebalancingEndTime: (value: React.SetStateAction<Date>) => void;
  withRebalancing: boolean;
  chartsToDisplay: Charts[];
}

export enum DistribId {
  'MFAAAS' = 'mfaaas',
  'BPF' = 'bpf',
  'BDDF' = 'bddf',
  'HB_BE' = 'hb_be',
  'DIGITAL_CLUBS' = 'digital-clubs',
  'MFAAAS_V2' = 'mfaaas_v2',
}

export type Kpis = {
  avgDuration: Kpi;
  maxDuration: Kpi;
  durationUnder2s: Kpi;
  durationUnder5s: Kpi;
  avgFinalVol: Kpi;
  avgInitialVol: Kpi;
  avgFinalRisk: Kpi;
  avgInitialRisk: Kpi;
  avgFinalESGWeight: Kpi;
  avgInitialESGWeight: Kpi;
  avgFinalTrackingError: Kpi;
  avgInitialTrackingError: Kpi;
  avgInvestmentAmount: Kpi;
  maxInvestmentAmount: Kpi;
  avgPortfolioAmount: Kpi;
  maxPortfolioAmount: Kpi;
  avgInitialEuroFundWeight: Kpi;
  avgInitialReimUCWeight: Kpi;
  avgInitialUCWeight: Kpi;
  avgFinalEuroFundWeight: Kpi;
  avgFinalReimUCWeight: Kpi;
  avgFinalUCWeight: Kpi;
  maxFundsDuringRebalancing: Kpi;
  avgFundsDuringRebalancing: Kpi;
};
