import { RowChart as DcRowChart } from 'dc';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { IChart, IRowChartProps } from 'utils/chartsUtils';
import { formatPercentage } from '../../utils/numbersUtils';
import useChart from './useChart';

const RowChart = forwardRef<IChart, IRowChartProps>(
  (
    {
      logKey,
      cf,
      height,
      title,
      defaultDimensionName,
      dimensionFilter,
      onFilter,
      withDataGrouping,
      withLabel,
    }: IRowChartProps,
    ref,
  ) => {
    const resetRef = useRef<HTMLSpanElement>(null);

    const getLabel = useCallback(
      (d: any, chart: DcRowChart) => {
        if (chart?.hasFilter() && !chart.hasFilter(d.key)) {
          return d.key;
        }

        let label = d.key;
        const value = cf?.all.value();

        if (value) {
          label += ` (${formatPercentage(d.value / value)})`;
        }

        return label;
      },
      [cf?.all],
    );

    const [chart, dimension, group, chartRef, reset] = useChart(
      cf,
      cf?.dc.rowChart,
      logKey,
      defaultDimensionName,
      dimensionFilter,
    );

    useEffect(() => {
      if (withDataGrouping) {
        chart?.data((chartGroup) => chartGroup.top(10));
      }

      chart
        ?.controlsUseVisibility(true)
        .dimension(dimension)
        .elasticX(true)
        .group(group)
        .height(height)
        .margins({ top: 10, right: 20, bottom: 30, left: 20 })
        .on('filtered', () => {
          if (onFilter) onFilter();
        })
        .width(280)
        .xAxis()
        .ticks(3);

      if (withLabel) {
        chart?.label((d) => getLabel(d, chart));
      }

      chart?.render();

      if (resetRef.current) resetRef.current.style.visibility = 'hidden';
    }, [chart, dimension, getLabel, group, height, onFilter, withDataGrouping, withLabel]);

    useImperativeHandle(
      ref,
      () => ({
        update() {
          chart?.redraw();
        },
      }),
      [chart],
    );

    return (
      <div data-chart-name={title} ref={chartRef}>
        <strong className="Chart-title">{title}</strong>
        <span
          className="reset"
          ref={resetRef}
          style={{
            visibility: 'hidden',
          }}
        >
          &nbsp;|&nbsp;
          <a href="/" onClick={reset}>
            Reset filter
          </a>
        </span>
        <div />
      </div>
    );
  },
);

export default RowChart;
