import { getHours, getMinutes } from 'date-fns';

export const hoursMinutestoDate = (time: string): Date => {
  const [hours, minutes] = time.split(':');
  const date = new Date();
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));
  date.setSeconds(0);
  return date;
};

export const isBetweenTime = (date: Date, startTime: Date, endTime: Date): boolean =>
  getHours(startTime) * 60 + getMinutes(startTime) <= getHours(date) * 60 + getMinutes(date) &&
  getHours(date) * 60 + getMinutes(date) <= getHours(endTime) * 60 + getMinutes(endTime);

export const formatDuration = (duration: number) =>
  `${Math.floor(duration / 1000)} s ${Math.floor(duration % 1000)} ms`;
