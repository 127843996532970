import { formatDuration } from './dateUtils';
import { formatAmount } from './numbersUtils';

export default class Kpi {
  value: number | null;

  checked: boolean;

  count: number;

  weightSum: number;

  constructor(value = null, checked = false) {
    this.value = value;
    this.checked = checked;
    this.count = 0;
    this.weightSum = 0;
  }

  reset = () => {
    this.checked = false;
  };

  toggle = () => {
    this.checked = !this.checked;
  };

  valid = () => this.value !== null;

  maxOf = (value: number | null) => {
    if (value === null) {
      return;
    }

    if (this.value === null || value > this.value) {
      this.value = value;
    }
  };

  sumOf = (value: number | null) => {
    if (value === null) {
      return;
    }

    if (this.value === null) {
      this.value = value;
    } else {
      this.value += value;
    }

    this.count += 1;
  };

  avg = () => {
    if (this.count && this.value) {
      this.value /= this.count;
    }
  };

  weightedSumOf = (value: number | null, weight: number) => {
    if (value === null) {
      return;
    }

    if (this.value === null) {
      this.value = value * weight;
    } else {
      this.value += value * weight;
    }

    this.weightSum += weight;
  };

  weightedAvg = () => {
    if (this.weightSum && this.value) {
      this.value /= this.weightSum;
    }
  };

  clear = () => {
    this.count = 0;
    this.value = null;
    this.weightSum = 0;

    return this;
  };

  formatAsAmount = () => {
    if (this.value) {
      return formatAmount(this.value);
    }

    return '-';
  };

  formatAsDuration = () => {
    if (this.value) {
      return formatDuration(this.value);
    }

    return '-';
  };

  formatAsPercentage = () => {
    if (this.value !== null) {
      return `${formatAmount(this.value)}%`;
    }

    return '-';
  };
}
