import { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material';

function ErrorDialog({ ...props }: { message: string | null; onErrorDisplay: () => void }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    props.onErrorDisplay();
  };

  useEffect(() => setOpen(props.message !== null), [props.message]);

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      fullWidth
      onClose={handleClose}
      open={open}
    >
      <StyledDialogTitle id="alert-dialog-title">Error</StyledDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" marginTop="10px">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogButtons>
        <DialogButton autoFocus disableRipple onClick={handleClose}>
          Close
        </DialogButton>
      </DialogButtons>
    </Dialog>
  );
}

export const StyledDialogTitle = styled(DialogTitle)`
  background-color: #424242;
  margin-bottom: 8px;
  text-align: center;
  color: white;
`;

export const DialogButton = styled(Button)`
  background-color: #00965e;
  border-radius: 3px;
  width: 100%;
  color: white;

  &:hover {
    background-color: #00a76f;
  }

  & > span {
    color: white;
  }
`;

export const DialogButtons = styled(DialogActions)`
  margin-bottom: 16px;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: center;
`;

export default ErrorDialog;
