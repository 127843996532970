// Here is where you can define configuration overrides based on the execution environment.
// Supply a key to the default environment matching the REACT_APP_ENV that you wish to target, and
// the base configuration will apply your overrides before exporting itself.
export default {
  // ======================================================
  // Overrides when REACT_APP_ENV === 'development'
  // ======================================================
  development: {
    AUTHENTICATOR_URL: 'https://canary-api.bnpparibas-am.staging.echonet/authenticator',
    MONITORING_URL: 'https://canary-api.bnpparibas-am.staging.echonet/monitoring', // 'http://127.0.0.1:5005/monitoring'
    SMART_ALLOCATION_URL: 'https://smart-allocation.am.staging.echonet/smart-allocation',
  },
  // ======================================================
  // Overrides when REACT_APP_ENV === 'integration'
  // ======================================================
  integration: {
    AUTHENTICATOR_URL: 'https://canary-api.bnpparibas-am.staging.echonet/authenticator',
    MONITORING_URL: 'https://canary-api.bnpparibas-am.staging.echonet/monitoring',
    SMART_ALLOCATION_URL: 'https://smart-allocation.am.staging.echonet/smart-allocation',
  },
  // ======================================================
  // Overrides when REACT_APP_ENV === 'preproduction'
  // ======================================================
  preproduction: {
    AUTHENTICATOR_URL: 'https://canary-api.bnpparibas-am.staging.echonet/authenticator',
    MONITORING_URL: 'https://canary-api.bnpparibas-am.staging.echonet/monitoring',
    SMART_ALLOCATION_URL: 'https://smart-allocation.am.staging.echonet/smart-allocation',
  },
  // ======================================================
  // Overrides when REACT_APP_ENV === 'production'
  // ======================================================
  production: {
    AUTHENTICATOR_URL: 'https://api.bnpparibas-am.com/authenticator',
    MONITORING_URL: 'https://api.bnpparibas-am.com/monitoring',
    SMART_ALLOCATION_URL: 'https://api.bnpparibas-am.com/smart-allocation',
  },
  // ======================================================
  // Overrides when REACT_APP_ENV === 'test'
  // ======================================================
  test: {
    AUTHENTICATOR_URL: 'https://canary-api.bnpparibas-am.staging.echonet/authenticator',
    MONITORING_URL: 'https://canary-api.bnpparibas-am.staging.echonet/monitoring',
    SMART_ALLOCATION_URL: 'https://smart-allocation.am.staging.echonet/smart-allocation',
  },
} as const;
