import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import GetApp from '@mui/icons-material/GetApp';
import Refresh from '@mui/icons-material/Refresh';

import { Button, TextField, styled } from '@mui/material';

import { format, parse, startOfDay } from 'date-fns';
import { chunk } from 'lodash';
import { IChart, getChart } from 'utils/chartsUtils';
import { IDashboardProps } from 'utils/types';
import KpiBar from '../KpiBar/KpiBar';
import LogTable from '../LogTable/LogTable';
import VolumeChart from '../charts/VolumeChart';

export interface IDashboard {
  updateAllCharts: () => void;
}

const Dashboard = forwardRef<IDashboard, IDashboardProps>(
  (
    {
      chartsToDisplay,
      beginDate,
      endDate,
      cf,
      filteredLogs,
      distribId,
      exportData,
      fetchData,
      kpis,
      startWorking,
      stopWorking,
      updateCharts,
      workers,
      setErrorMessage,
      rebalancingBeginTime,
      rebalancingEndTime,
      setRebalancingBeginTime,
      setRebalancingEndTime,
      withRebalancing,
    }: IDashboardProps,
    ref,
  ) => {
    const volumeChartRef = useRef<IChart>(null);
    const childrenRefs = useRef<{ [key: string]: IChart }>({});
    const [newBeginDate, setNewBeginDate] = useState(beginDate);
    const [newEndDate, setNewEndDate] = useState(endDate);

    const updateAllCharts = useCallback(() => {
      updateCharts();
      volumeChartRef.current?.update();

      chartsToDisplay.forEach((chart) => {
        if (chart.name !== 'empty') childrenRefs.current[chart.logKey].update();
      });
    }, [chartsToDisplay, updateCharts]);

    useImperativeHandle(
      ref,
      () => ({
        updateAllCharts,
      }),
      [updateAllCharts],
    );

    const toggleMaxDuration = () => {
      if (cf) {
        const dimension = cf.durationDimension;

        kpis.maxDuration.toggle();
        if (kpis.maxDuration.checked && kpis.maxDuration.value) {
          dimension.filter(kpis.maxDuration.value);
        } else {
          dimension.filterAll();
        }

        updateAllCharts();
      }
    };

    const toggleMaxPortfolioAmount = () => {
      if (cf) {
        const dimension = cf.portfolioAmountDimension;

        kpis.maxPortfolioAmount.toggle();

        if (kpis.maxPortfolioAmount.checked && kpis.maxPortfolioAmount.value) {
          dimension.filter(kpis.maxPortfolioAmount.value);
        } else {
          dimension.filterAll();
        }
        updateAllCharts();
      }
    };

    const toggleMaxInvestmentAmount = () => {
      if (cf) {
        const dimension = cf.investmentAmountDimension;

        kpis.maxInvestmentAmount.toggle();

        if (kpis.maxInvestmentAmount.checked && kpis.maxInvestmentAmount.value) {
          dimension.filter(kpis.maxInvestmentAmount.value);
        } else {
          dimension.filterAll();
        }

        updateCharts();
      }
    };

    const getChartColumns = useCallback(() => {
      const columnChunks = chunk(chartsToDisplay, 3);
      return columnChunks.map((columnChunk) => (
        <div className="Column" key={columnChunk.map((chart) => chart.logKey).join('-')}>
          {columnChunk.map((chart) =>
            getChart({
              ...chart,
              cf: cf!!,
              onFilter: updateAllCharts,
              ref: (chartRef: IChart) => {
                childrenRefs.current[chart.logKey] = chartRef;
              },
            }),
          )}
        </div>
      ));
    }, [cf, chartsToDisplay, updateAllCharts]);

    return (
      cf &&
      distribId && (
        <>
          <KpiBar
            distribId={distribId}
            kpis={kpis}
            toggleMaxDuration={toggleMaxDuration}
            toggleMaxInvestmentAmount={toggleMaxInvestmentAmount}
            toggleMaxPortfolioAmount={toggleMaxPortfolioAmount}
            withRebalancing={withRebalancing}
          />
          <div className="Column">
            <div className="Row">
              <VolumeChart
                beginDate={beginDate}
                cf={cf}
                endDate={endDate}
                onFilter={updateCharts}
                ref={volumeChartRef}
              />
              <div>
                <div className="Row">
                  <TextField
                    defaultValue={format(newBeginDate, 'yyyy-MM-dd')}
                    disabled={workers > 0}
                    id="beginDate"
                    label="Begin Date"
                    onChange={(event) => setNewBeginDate(startOfDay(new Date(event.target.value)))}
                    required
                    type="date"
                    sx={{ marginLeft: '20px' }}
                    variant="standard"
                  />
                  <TextField
                    defaultValue={format(newEndDate, 'yyyy-MM-dd')}
                    disabled={workers > 0}
                    id="endDate"
                    label="End Date"
                    onChange={(event) => setNewEndDate(startOfDay(new Date(event.target.value)))}
                    required
                    type="date"
                    sx={{ marginLeft: '20px' }}
                    variant="standard"
                  />
                  <ChartAction
                    className="App-refresh"
                    disableRipple
                    onClick={() => {
                      fetchData(newBeginDate, newEndDate, distribId);
                    }}
                    title="Click to Refresh Data"
                    variant="contained"
                  >
                    Refresh
                    <Refresh className="App-refresh-icon" />
                  </ChartAction>
                  <ChartAction
                    className="App-export"
                    disableRipple
                    onClick={() => exportData(beginDate, endDate, distribId)}
                    title="Click to export data on excel"
                    variant="contained"
                  >
                    Export
                    <GetApp className="App-export-icon" />
                  </ChartAction>
                </div>
                {withRebalancing && (
                  <div className="Row" style={{ marginTop: '15px' }}>
                    <TextField
                      defaultValue={format(rebalancingBeginTime, 'HH:mm')}
                      disabled={workers > 0}
                      id="rebalancingBeginDate"
                      label="Rebalancing Begin Time"
                      onChange={(event) =>
                        setRebalancingBeginTime(parse(event.target.value, 'HH:mm', new Date()))
                      }
                      required
                      type="time"
                      sx={{ marginLeft: '20px', width: '25%' }}
                      variant="standard"
                    />
                    <TextField
                      defaultValue={format(rebalancingEndTime, 'HH:mm')}
                      disabled={workers > 0}
                      id="rebalancingEndDate"
                      label="Rebalancig End Time"
                      onChange={(event) =>
                        setRebalancingEndTime(parse(event.target.value, 'HH:mm', new Date()))
                      }
                      required
                      type="time"
                      sx={{ marginLeft: '20px', width: '25%' }}
                      variant="standard"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="Row" style={{ maxWidth: '100vw' }}>
              <LogTable
                logs={filteredLogs}
                startWorking={startWorking}
                stopWorking={stopWorking}
                onLogRequestError={(errorMessage: string) => setErrorMessage(errorMessage)}
                distribId={distribId}
              />
            </div>
            <div className="Row">{getChartColumns()}</div>
          </div>
        </>
      )
    );
  },
);

const ChartAction = styled(Button)`
  height: 36px;
  margin-left: 20px;
  margin-top: 10px;
  background-color: #ddd;
  color: black;

  &:hover {
    background-color: #00a76f;
    border-radius: 5px;
    color: white;
  }
`;

export default Dashboard;
