import { LegacyLog } from 'utils/types';
import { PERIODICITY_TO_MULTIPLICATOR_AMOUNT_LOOKUP } from './constants';

const amountFormatter = new Intl.NumberFormat('fr-FR', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const countFormatter = new Intl.NumberFormat('fr-FR', {
  maximumFractionDigits: 0,
});

const percentageFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'percent',
});

const formatAmount = (amount: number) => {
  if (amount !== null) {
    return amountFormatter.format(amount).replace(/,/, '.');
  }
  return '-';
};

const formatCount = (count: number) => {
  if (count !== null) {
    return countFormatter.format(count);
  }
  return '-';
};

const formatPercentage = (unitInterval: number) => percentageFormatter.format(unitInterval);

const getLogInvestmentAmount = (log: LegacyLog) => {
  if (log.investment_amount) {
    // Handle VC
    let multiplicator = 1;
    if (log.investment_periodicity) {
      // Handle VR
      multiplicator = PERIODICITY_TO_MULTIPLICATOR_AMOUNT_LOOKUP[log.investment_periodicity];
    }
    return log.investment_amount * multiplicator;
  }
  return 0;
};

export { formatAmount, formatCount, formatPercentage, getLogInvestmentAmount };
