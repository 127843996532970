import { Color } from 'd3';
import { scaleOrdinal } from 'd3-scale';
import { PieChart as DcPieChart, Scale } from 'dc';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { IChart, IPieChartProps } from 'utils/chartsUtils';
import { formatPercentage } from '../../utils/numbersUtils';
import useChart from './useChart';

const PieChart = forwardRef<IChart, IPieChartProps>(
  (
    {
      cf,
      logKey,
      title,
      colors,
      defaultDimensionName,
      dimensionFilter,
      height,
      onFilter,
      width,
    }: IPieChartProps,
    ref,
  ) => {
    const resetRef = useRef<HTMLSpanElement>(null);

    const getLabel = useCallback(
      (d: any, chart: DcPieChart) => {
        if (chart?.hasFilter() && !chart.hasFilter(d.key)) {
          return d.key;
        }

        let label = '0%';

        const value = cf?.all.value();

        if (value) {
          label = formatPercentage(d.value / value);
        }

        return label;
      },
      [cf?.all],
    );

    const [chart, dimension, group, chartRef, reset] = useChart(
      cf,
      cf?.dc.pieChart,
      logKey,
      defaultDimensionName,
      dimensionFilter,
    );

    useEffect(() => {
      if (!cf) return;

      if (colors) {
        chart
          ?.colorAccessor((d) => d.key)
          .colors(
            scaleOrdinal().domain(colors.domain).range(colors.range) as Scale<string | Color>,
          );
      }

      chart
        ?.controlsUseVisibility(true)
        .cx(75)
        .cy(75)
        .dimension(dimension)
        .group(group)
        .height(height || 150)
        .label((d) => getLabel(d, chart))
        .legend(cf.dc.legend().x(150).y(10))
        .on('filtered', () => {
          if (onFilter) {
            onFilter();
          }
        })
        .radius(65)
        .width(width || 250)
        .height(height || 200);

      chart?.render();
      if (resetRef.current) resetRef.current.style.visibility = 'hidden';
    }, [cf, chart, colors, dimension, getLabel, group, height, onFilter, width]);

    useImperativeHandle(
      ref,
      () => ({
        update() {
          chart?.redraw();
        },
      }),
      [chart],
    );
    return (
      <div data-chart-name={title} ref={chartRef}>
        <strong className="Chart-title">{title}</strong>
        <span
          className="reset"
          ref={resetRef}
          style={{
            visibility: 'hidden',
          }}
        >
          &nbsp;|&nbsp;
          <a href="/" onClick={reset}>
            Reset filter
          </a>
        </span>
        <div />
      </div>
    );
  },
);

export default PieChart;
